import React from 'react';
import PropTypes from 'prop-types';

export const Image = ({ src, alt, flexChild, className }) => {
  return flexChild ? (
    <div className={className}>
      <img src={src} alt={alt || ""} />
    </div>
  ) : (
    <img className={className} src={src} alt={alt || ""} />
  )
}

Image.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
  flexChild: PropTypes.bool,
  className: PropTypes.string,
};

export default Image
