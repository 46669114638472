/* eslint-disable eqeqeq */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from '../atoms/Image'
import './card.sass'

export const Card = ({ action, layout, target, image, title, dek, author, ...props }) => (
  <section {...props} className={`card card--${layout} card--${action} ${props.className}`}>
    <Link className="card__link" to={target}>
      {action && <p className="card__action">{action}</p>}

      <div className="card__main">
        {image && <Image
          className="card__image"
          src={image.rendition.url}
          alt={image.alt}
          flexChild={action == "watch"}
        />}
        
        <div className="card__text">
          {title && <h1 className="card__title">{title}</h1>}
          {dek && <p className="card__dek">{dek}</p>}
          {author && <p className="card__author">by {author}</p>}
        </div>
      </div>
    </Link>
  </section>
)

Card.propTypes = {
  layout: PropTypes.string,
  action: PropTypes.string,
  target: PropTypes.string.isRequired,
  image: PropTypes.shape({
    title: PropTypes.string,
    rendition: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  dek: PropTypes.string,
  author: PropTypes.string,
}

export default Card